import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import env from '../enviorments'
import header from '../header'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: null,
        user: null,
    },
    getters: {

    },
    mutations: {
        mutateUser(state, user) {
            state.user = user
        },
        mutateToken(state, token) {
            state.token = token
        },
    },
    actions: {
        //Auth
        async userLogin({commit}, user) {
            const result = (await axios.post(env.apiUrl + 'login', {...user})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            window.localStorage.setItem('token', JSON.stringify(result.data.token))
            result.data.password = null,
            window.localStorage.setItem('user', JSON.stringify(result.data))
            commit('mutateToken', result.token)
            commit('mutateUser', result.data)
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Dashboard
        async dashboardAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'dashboard', {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Member
        async memberListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'member-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Icon
        async iconListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'icon-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async iconDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'icon-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async iconStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'icon-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async iconUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'icon-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Update Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        //Category
        async categoryListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async categoryDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async categoryStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'category-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }

        },
        async categoryUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'category-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async categoryDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'category-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Content
        async contentListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'content-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async contentDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'content-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async contentStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'content-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }

        },
        async contentUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'content-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async contentDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'content-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Subscription
        async permissionDetailAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'subscription-pro-detail', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async permissionUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'subscription-pro-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Product
        async productListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'product-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async productDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'product-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async productStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'product-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async productUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'product-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        //SKU
        async skuListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'sku-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async skuDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'sku-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async skuStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'sku-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async skuUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'sku-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async skuDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'sku-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Order
        async orderListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'order-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async saleOrderInfoAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'order-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async saleOrderUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'order-status-changing', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async saleOrderDetailUpdateAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'qr-making', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Upload
        async massUploadAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'mass-upload',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in massUploadAction:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async generatePreCardAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'generate-pre-card', {...option}, {headers: header.getToken()})).data
            if (result.success == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Agent
        async agentListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'agent-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async agentStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'agent-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async agentDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'agent-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async agentUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'agent-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async agentDashboardAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'agent-dashboard', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async agentOrderAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'agent-order', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async agentReceiveAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'agent-receive', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async receiveListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'receive-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async receiveDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'receive-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async receiveCheckAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'receive-check', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async receiveStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'receive-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async receiveUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'receive-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async receiveDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'receive-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
    },
    modules: {
    }
})
