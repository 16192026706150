import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/admin',
		name: 'admin',
		component: Admin,
		children: [{
			path: '/dashboard',
			name: 'dashboard',
			component: () => import('../views/Dashboard'),
		},
		{
			path: '/member-list',
			name: 'member-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/member/accountList'),
		},
		{
			path: '/icon-list',
			name: 'icon-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/icon/iconList'),
		},
		{
			path: '/icon-create',
			name: 'icon-create',
			component: () => import('../views/icon/iconCreate'),
		},
		{
			path: '/icon-edit',
			name: 'icon-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/icon/iconEdit'),
		},
		{
			path: '/category-list',
			name: 'category-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/category/categoryList'),
		},
		{
			path: '/category-create',
			name: 'category-create',
			component: () => import('../views/category/categoryCreate'),
		},
		{
			path: '/category-edit',
			name: 'category-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/category/categoryEdit'),
		},
		{
			path: '/content-list',
			name: 'content-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/content/contentList'),
		},
		{
			path: '/content-create',
			name: 'content-create',
			component: () => import('../views/content/contentCreate'),
		},
		{
			path: '/content-edit',
			name: 'content-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/content/contentEdit'),
		},
		{
			path: '/subscription',
			name: 'subscription',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/subscription/permission'),
		},
		{
			path: '/product-list',
			name: 'product-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productList'),
		},
		{
			path: '/product-create',
			name: 'product-create',
			component: () => import('../views/product/productCreate'),
		},
		{
			path: '/product-edit',
			name: 'product-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productEdit'),
		},
		{
			path: '/sku-list',
			name: 'sku-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/skuList'),
		},
		{
			path: '/order-list',
			name: 'order-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				shopId: '',
				fromDate: '',
				toDate: '',
				status: ''
			  },
			component: () => import('../views/saleorder/saleOrderList'),
		},
		{
			path: '/sale-order-detail',
			name: 'sale-order-detail',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				salonId: '',
				fromDate: '',
				toDate: '',
				status: ''
			  },
			component: () => import('../views/saleorder/saleOrderDetail'),
		},
		{
			path: '/agent-list',
			name: 'agent-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/agent/agentList'),
		},
		{
			path: '/agent-create',
			name: 'agent-create',
			component: () => import('../views/agent/agentCreate'),
		},
		{
			path: '/agent-edit',
			name: 'agent-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/agent/agentEdit'),
		},
		{
			path: '/agent-dashboard',
			name: 'agent-dashboard',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/agent/agentDashboard'),
		},
		{
			path: '/agent-order',
			name: 'agent-order',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/agent/agentOrder'),
		},
		{
			path: '/agent-receive',
			name: 'agent-receive',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/agent/agentReceive'),
		},
		{
			path: '/receive-list',
			name: 'receive-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/receive/receiveList'),
		},
		{
			path: '/receive-create',
			name: 'receive-create',
			component: () => import('../views/receive/receiveCreate'),
		},
		{
			path: '/receive-edit',
			name: 'receive-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/receive/receiveEdit'),
		},
		]
	},

	{
		path: '/*',
		redirect: '/dashboard'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router